



















































import { Component, Vue } from "vue-property-decorator";
import { AuthModule } from "@/store/modules/auth";
import { getLoginUrl } from "@/services/auth.service";
import GitVersion from "@/components/git-version/GitVersion.vue";

@Component({
  name: "Login",
  components: {
    GitVersion,
  },
})
export default class extends Vue {
  public alert = false;
  public errorMessage = "";
  private defaultAlertType: string = "error";
  private alertType: string = this.defaultAlertType;
  public async loginAzure() {
    try {
      const res: unknown = await getLoginUrl();
      const url = (res as { loginUrl: string }).loginUrl;
      window.location.href = url;
    } catch (e) {
      throw e;
    }
  }
  public mounted() {
    if ("errorMessage" in this.$route.params) {
      const alertType = this.$route.params.alertType;
      this.alertType = alertType ? alertType : this.defaultAlertType;
      this.errorMessage = this.$route.params.errorMessage;
      AuthModule.logout();
      this.alert = true;
    }
  }
}
