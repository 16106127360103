







import { Vue, Component } from "vue-property-decorator";
import { isNil } from "lodash";
import { getBackendVersion } from "@/services/version.service";

@Component
export default class GitVersion extends Vue {
  private backendVersion: string = "";

  private async created() {
    await this.getBackendVersion();
  }

  private async getBackendVersion() {
    let backendVersion: string;
    try {
      backendVersion = await getBackendVersion();
    } catch (_) {
      backendVersion = "";
    }
    this.backendVersion = backendVersion;
  }

  private get frontendVersion(): string {
    if (isNil(process.env.VUE_APP_GIT_HASH)) {
      return "";
    }
    return process.env.VUE_APP_GIT_HASH;
  }
}
